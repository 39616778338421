import React from "react";

export default function BackOfficeContent() {
  return (
    <div>
      <div className="back-office__content-wrapper">
        <h1>Welcome</h1>
        <p>
          Here is the backoffice, you can add Hero/Vilains/Both Characters, you
          can add Locations and add Boxes you have. For generate a party of
          Marvel United, you have to save the data of what boxes, characters and
          locations you have. Please enter your data.
        </p>
      </div>
    </div>
  );
}
